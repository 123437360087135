import { getEstateData } from '../thunks/estateThunks';
import { INIT_ESTATE, ESTATE_LOADED } from '../actions/estateActions';
import { setPath } from '../actions/appActions';
const estateMiddleware = (useCloudStorageResult, useTelegramResult) => (store) => (next) => (action) => {
  const { dispatch, getState } = store;
  const { setItem, getItemAsync } = useCloudStorageResult;  
  const result = next(action);

  switch (action.type){
    case INIT_ESTATE:
      dispatch(getEstateData(useCloudStorageResult));
      break;
    case ESTATE_LOADED:
      dispatch(setPath('/property'));
      break;
    default:break;
  }
  return result;
};

export default estateMiddleware;