export const text = {
   ru: {
      textMainBtn: 'Получить консультацию',
      header: {
         info: 'Инфо',
         favorite: 'Избранное',
         infoText:
            'Возможность выгодного приобретения инвестиционной жилой и коммерческой недвижимости в России и за рубежом',
         investionText:
            'Вы несете полную ответственность за свои инвестиционные решения. Платформа не несет ответственности за ваши возможные убытки.',
      },
      filter: {
         price: 'Цена',
         from: 'от',
         to: 'до',
         propertyTypes: {
            name: 'Купить',
            residential: 'Жилая недвижимость',
            commercial: 'Коммерческая недвижимость',
            top: 'ТОП-предложения недели',
         },
         residentialRealEstateTypes: {
            name: 'Тип жилой недвижимости',
            flat: 'Квартиры',
            village: 'Виллы и бунгало',
            townHouse: 'Таунхаусы',
            elite: 'Элитная недвижимость',
            cheap: 'Недорогая недвижимость',
            nearBySea: 'Рядом с морем',
         },
         commercialRealEstateTypes: {
            name: 'Тип коммерческой недвижимости',
            var1: 'Вариант 1',
            var2: 'Вариант 2',
         },
         country: {
            name: 'Страна',
            Russia: 'Россия',
            Turkey: 'Турция',
            NorthernCyprus: 'Северный Кипр',
         },
         paymentMethod: {
            name: 'Способ приобретения',
            cash: '100% наличные денежные средства',
            split: 'Рассрочка',
            mortgage: 'Ипотека',
            crypto: 'Криптовалюта',
         },
         investionMethod: {
            name: 'Способ инвестирования',
            usd: 'USD',
            rub: 'RUB',
            crypto: 'CRYPTO',
         },
         biz: {
            name: 'Ниша бизнеса',
            nedvijka: 'Недвижимость',
            crypto: 'Криптовалюта',
         },
      },
   },
}
