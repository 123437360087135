import styled from 'styled-components'
import Heart from '../../image/HeartItem.svg'
import RedHeart from '../../image/png/redHeart.png'
import SotkaBG from '../../image/sotkaBG.png'
import BaseButton from '../baseButton/BaseButton'
import LinkButton from '../linkButton/LinkButton'
import $api from '../../http'
import { useEffect, useState } from 'react'

const SotkaCard = ({ name, id, is_favorite, preview_image, clickFav, setcCickFav }) => {
   const [favorite, setFavorite] = useState()

   useEffect(() => {
      setFavorite(is_favorite)
   }, [is_favorite])

   const updateFav = async () => {
      favorite
         ? await $api.post('/investments/delete_favorite', { investment_id: id })
         : await $api.post('/investments/add_favorite', { investment_id: id })

      setFavorite(!favorite)
      setcCickFav(clickFav + 1)
   }
   return (
      <Wrapper className='wrapper'>
         <Content images={preview_image}>
            <Action>
               <div>{name}</div>
               <ActiveWrapper>
                  <BaseButton
                     link={`/sotka-card/${id}`}
                     text={'Подробнее'}
                     mainButton={true}
                  ></BaseButton>
                  <LinkButton link={`/sotka-card/${id}`} mainButton={true} />
               </ActiveWrapper>
            </Action>
            {/* <Sotka src={SotkaImg} alt='sotka' /> */}
            {favorite ? (
               <HeartIcon onClick={() => updateFav()}>
                  <img src={RedHeart} alt='heart' />
               </HeartIcon>
            ) : (
               <WhiteHeartIcon onClick={() => updateFav()}>
                  <img src={Heart} alt='heart' />
               </WhiteHeartIcon>
            )}
         </Content>
      </Wrapper>
   )
}

export default SotkaCard

const ActiveWrapper = styled.div`
   display: flex;
`

const HeartIcon = styled.div`
   position: absolute;
   top: 11px;
   right: 12px;
   cursor: pointer;
   height: 34px;
   img {
      height: 100%;
   }
`

const WhiteHeartIcon = styled.div`
   position: absolute;
   top: 14px;
   right: 14px;
   cursor: pointer;
   height: 27px;
   img {
      height: 100%;
   }
`

const Action = styled.div`
   display: flex;
   justify-content: space-between;
   flex-direction: column;
   font-size: 24px;
   font-weight: 700;
   line-height: 31.2px;
   letter-spacing: -0.02em;
   color: #fff;
   padding: 30px 0 30px 20px;
   height: 80%;
`

const Content = styled.div`
   width: 100%;
   height: 100%;
   display: flex;
   border-radius: 16px;
   justify-content: space-beetwen;
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   background-image: url(${(props) => (!!props?.images?.length ? props.images : SotkaBG)});
   justify-content: space-between;
   align-items: center;
`

const Wrapper = styled.div`
   height: 183px;
   width: 100%;
   border-radius: 16px;
   position: relative;
   margin-top; 20px;
   background: #010753;
   margin-top: 16px;
`
