import Gallery1 from "../../image/photo1.png";
import Gallery2 from "../../image/photo2.png";
import Gallery3 from "../../image/photo3.png";
import styled from "styled-components";

const Gallery = () => {
  return (
    <Wrapper className={"wrapper"} id={"social"}>
      <img src={Gallery1} alt="photo1" />
      <img src={Gallery2} alt="photo2" />
      <img src={Gallery3} alt="photo3" />
    </Wrapper>
  );
};

export default Gallery;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  img {
    margin-top: 15px;
    border-radius: 16px;
    width: 100%;
  }
`;
