import classes from './ModalsGrid.module.css'
import Wallet from '../../image/WalletImage.svg'
import styled from 'styled-components'

const ModalBalanceInfo = () => {
   return (
      <div className={classes.wrapper}>
         <img src={Wallet} alt='wallet' />
         <BalanceText className={classes.subText}>Ваш баланс</BalanceText>
         <Balance className={classes.priceText}>100 300 157 ₽</Balance>
      </div>
   )
}

export default ModalBalanceInfo

const BalanceText = styled.div`
   margin-top: 10px;
`

const Balance = styled.div`
   margin-top: 10px;
   font-family: system-ui;
`
