import React, { useMemo, useState, useEffect } from 'react'
import classes from './Estate.module.css'
import ImageSlider from '../imageSlider/ImageSlider'
import { useParams } from 'react-router-dom'
import $api from '../../http'
import { useDispatch } from 'react-redux'
import { setPath } from '../../store/actions/appActions'
import { estateLoaded } from '../../store/actions/estateActions'

const Estate = () => {
   const [item, setItemEs] = useState({})
   const dispatch = useDispatch()
   const params = useParams()
   useEffect(() => {
      dispatch(setPath('/property'))
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useMemo(async () => {
      const response = await $api.get(`/complex/${params.id}`)
      setItemEs(response.data)
      dispatch(estateLoaded(response.data))
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [params.id])

   const formatNumber = (number) => {
      return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
   }

   const estateImages = item?.estates?.[0]?.images

   const convertPayment = (method) => {
      switch (method) {
         case 'installment':
            return 'Рассрочка'
         case 'crypto':
            return 'Криптовалюта'
         case 'mortgage':
            return 'Ипотека'
         case 'cash':
            return 'Наличные'
         default:
            return ''
      }
   }

   return (
      <div className={`${classes.container} ${classes.visible}`}>
         {item && (
            <div className={classes._}>
               <div className={classes.block1}>
                  <ImageSlider photos={item?.images} />
               </div>
               <div className={classes.block1_5}>
                  {item?.min_prices?.map((el) => (
                     <div className={classes.block1_5_1}>
                        <div className={classes.infoText6}>{el?.num_rooms}х комнатная</div>
                        <div className={classes.infoText5}>от {formatNumber(el.price)}₽</div>
                     </div>
                  ))}
               </div>
               <div className={classes.infoBlock}>
                  <div className={classes.block3}>
                     <div className={classes.name3}>{item?.name}</div>
                     <div className={classes.infoText3}>
                        <div>Локация</div>
                        <div className={classes.border} />
                        <div>{item?.location ?? 'Не указано'}</div>
                     </div>
                     <div className={classes.infoText3}>
                        <div>Материал стен</div>
                        <div className={classes.border} />
                        <div>{item?.material ?? 'Не указано'}</div>
                     </div>
                     <div className={classes.infoText3}>
                        <div>Срок сдачи</div>
                        <div className={classes.border} />
                        <div>{item?.completion_dt ?? 'Не указано'}</div>
                     </div>
                     <div className={classes.infoText3}>
                        <div>Способ приобретения</div>
                        <div className={classes.border} />
                        <div className={classes.payMethod}>
                           {item?.payments?.map((pay) => (
                              <div className={classes.payBlock}>
                                 {convertPayment(pay) ?? 'Не указано'}
                              </div>
                           ))}
                        </div>
                     </div>
                     <div className={classes.infoText3}>
                        <div>Уникальность приобретения</div>
                        <div className={classes.border} />
                        <div>{item?.acquisition ?? 'Не указано'}</div>
                     </div>
                     {!!item?.info?.length && (
                        <div
                           className={classes.infoText4}
                           dangerouslySetInnerHTML={{ __html: item?.info }}
                        />
                     )}
                     <div className={classes.block4}>
                        <ImageSlider photos={estateImages} estates={item?.estates || []} />
                     </div>
                  </div>
               </div>
            </div>
         )}
      </div>
   )
}

export default Estate
