import React from 'react'
// import { createRoot } from 'react-dom'
import { createRoot } from 'react-dom/client'
import configureStore from './store/store'
import { Provider } from 'react-redux'
import { useCloudStorage } from './hooks/useCloudStorage'
import { useTelegram } from './hooks/useTelegram'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
const Root = () => {
   const cloudStorageResult = useCloudStorage()
   const telegramResult = useTelegram()
   const reduxStore = configureStore(cloudStorageResult, telegramResult)

   return (
      <BrowserRouter>
         <Provider store={reduxStore}>
            <App />
         </Provider>
      </BrowserRouter>
   )
}

const root = createRoot(document.getElementById('root'))
root.render(<Root />)
