import classes from './GridBotLink.module.css'
import ArrowImg from '../../image/arrowLink.svg'

const GridBotLink = ({ name, imgUrl, handleClick }) => {
   return (
      <div className={classes.wrapper} onClick={handleClick}>
         <div className={classes.imgWrapper}>
            <img src={imgUrl} alt='img' />
         </div>
         <div className={classes.title}>{name}</div>
         <img src={ArrowImg} alt='arrow' className={classes.arrowImg} />
      </div>
   )
}

export default GridBotLink
