import { 
    INIT_PROPERTY_PAGE,
    SAVE_END_INDEX,
    SAVE_SCROLL_TOP,
    SET_SELECTED_GOOD,
} from "../actions/propertyActions";

const propertyMiddleware = (useCloudStorageResult, useTelegramResult) => {
    return store => next => action => {
        const { dispatch, getState } = store;
        const { setItem, getItemAsync } = useCloudStorageResult;  
        const { webApp, lang } = useTelegramResult;
        if(action){
            switch(action?.type){
                case INIT_PROPERTY_PAGE:

                    break;
                case SAVE_END_INDEX:
                    // Обновляем значение endIndex в хранилище и в CloudStorage
                    setItem('endIndex', action.payload);
                    break;
                case SAVE_SCROLL_TOP:
                    // Обновляем значение scrollTop в хранилище и в CloudStorage
                    setItem('scrollTop', action.payload);
                    break;
                case SET_SELECTED_GOOD:
                    setItem('estate', action.payload);
                    webApp.BackButton.show();
                    window.location.href = '/estate';
                    break;
                default:
                    break;
            }
            return next(action);
        }
    }
}
    
export default propertyMiddleware;