import { SET_CURRENCY_RATES, SET_ACTIVE_CURRENCY  } from '../actions/currencyActions';

const initialState = {
  rates: {},
  activeCurrency: 'RUB',
};

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENCY_RATES:
      return {
        ...state,
        rates: action.payload,
      };
    case SET_ACTIVE_CURRENCY:
      return {
        ...state,
        activeCurrency: action.payload,
      };
    default:
      return state;
  }
};

export default currencyReducer;
