import React, { useEffect, useState } from 'react'
import classes from './Filter.module.css'
import Select from './select/Select'
import { text } from '../../translate'
import { useTelegram } from '../../hooks/useTelegram'
import Range from './range/Range'

const Filter = ({ items, setCardItems, isFav }) => {
   const { lang } = useTelegram()

   const [filPropertyTypes, setFilPropertyTypes] = useState('')
   const [filCountry, setFilCountry] = useState('')
   const [filResidential, setFilResidential] = useState('')
   const [filPayment, setFilPayment] = useState('')
   const [inputFrom, setInputFrom] = useState('')
   const [inputTo, setInputTo] = useState('')

   const propertyTypes = [
      {
         id: 1,
         name: text[lang].filter.propertyTypes.residential,
         value: 'living',
         fv: 'residential',
      },
      {
         id: 2,
         name: text[lang].filter.propertyTypes.commercial,
         value: 'commercial',
         fv: 'commercial',
      },
      { id: 3, name: text[lang].filter.propertyTypes.top, value: 'top', fv: 'top' },
   ]

   const residentialRealEstateTypes = [
      {
         id: 1,
         name: text[lang].filter.residentialRealEstateTypes.flat,
         value: 'apartment',
         fv: 'flat',
      },
      {
         id: 2,
         name: text[lang].filter.residentialRealEstateTypes.village,
         fv: 'village',
      },
      {
         id: 3,
         name: text[lang].filter.residentialRealEstateTypes.townHouse,
         value: 'townhouse',
         fv: 'townHouse',
      },
      { id: 4, name: text[lang].filter.residentialRealEstateTypes.elite, fv: 'elite' },
      { id: 5, name: text[lang].filter.residentialRealEstateTypes.cheap, fv: 'cheap' },
      { id: 6, name: text[lang].filter.residentialRealEstateTypes.nearBySea, fv: 'nearBySea' },
   ]

   const countries = [
      { id: 1, name: text[lang].filter.country.Russia, fv: 'Russia' },
      { id: 2, name: text[lang].filter.country.Turkey, fv: 'Turkey' },
      { id: 3, name: text[lang].filter.country.NorthernCyprus, fv: 'NorthernCyprus' },
   ]

   const paymentMethods = [
      { id: 1, name: text[lang].filter.paymentMethod.cash, value: 'cash', fv: 'cash' },
      { id: 2, name: text[lang].filter.paymentMethod.split, value: 'installment', fv: 'split' },
      { id: 3, name: text[lang].filter.paymentMethod.mortgage, value: 'mortgage', fv: 'mortgage' },
      { id: 4, name: text[lang].filter.paymentMethod.crypto, value: 'crypto', fv: 'crypto' },
   ]

   useEffect(() => {
      let res = items
      if (!!filPropertyTypes.length) {
         res = res?.filter((item) => item?.group === filPropertyTypes)
      }
      if (!!filResidential.length) {
         res = res?.filter((item) => item?.type === filResidential)
      }
      if (!!filCountry.length) {
         res = res?.filter((item) => item?.city?.country === filCountry)
      }
      if (!!filPayment.length) {
         res = res.filter((item) => item.payments.some((method) => method.includes(filPayment)))
      }
      if (!!inputFrom && !!inputTo) {
         res = res.filter((item) =>
            item.estate_prices.some((price) => price >= inputFrom && price <= inputTo),
         )
      }
      if (!!inputFrom) {
         res = res.filter((item) => item.estate_prices.some((price) => price >= inputFrom))
      }
      if (!!inputTo) {
         res = res.filter((item) => item.estate_prices.some((price) => price <= inputTo))
      }
      if (isFav) {
         res = res?.filter((item) => item?.is_favorite)
      }
      setCardItems(res)

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [filResidential, filCountry, filPropertyTypes, inputTo, inputFrom, filPayment, isFav, items])

   return (
      <div className={classes._ + ' wrapper'}>
         <Select
            id={1}
            name={text[lang].filter.propertyTypes.name}
            options={propertyTypes || []}
            state={filPropertyTypes}
            setState={setFilPropertyTypes}
         />
         {filPropertyTypes === 'living' && (
            <Select
               id={2}
               name={text[lang].filter.residentialRealEstateTypes.name}
               options={residentialRealEstateTypes || []}
               state={filResidential}
               setState={setFilResidential}
            />
         )}

         <Select
            id={4}
            name={text[lang].filter.country.name}
            options={countries || []}
            state={filCountry}
            setState={setFilCountry}
         />
         <Select
            id={5}
            name={text[lang].filter.paymentMethod.name}
            options={paymentMethods || []}
            state={filPayment}
            setState={setFilPayment}
         />
         <Range
            inputFrom={inputFrom}
            setInputFrom={setInputFrom}
            inputTo={inputTo}
            setInputTo={setInputTo}
         />
      </div>
   )
}

export default Filter
