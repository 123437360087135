import React, { useEffect, useState } from 'react'
import classes from './Card.module.css'
import Heart from '../../image/HeartItem.svg'
import RedHeart from '../../image/png/redHeart.png'
import $api from '../../http'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTelegram } from '../../hooks/useTelegram'
import { text } from '../../translate'

const Card = ({ id, name, image, estate_prices, is_favorite, clickFav, setcCickFav }) => {
   const currency = '₽'

   const [favorite, setFavorite] = useState()
   const { webApp, lang } = useTelegram()

   useEffect(() => {
      setFavorite(is_favorite)
   }, [is_favorite])

   const initMinPrice = !!estate_prices.length ? Math.min(...estate_prices) : '0'

   const navigate = useNavigate()

   const formatNumber = (number) => {
      return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
   }

   const updateFav = async () => {
      !favorite
         ? await $api.post('/complex/add_favorite', { complex_id: id })
         : await $api.post('/complex/delete_favorite', { complex_id: id })
      setFavorite(!favorite)
      setcCickFav(clickFav + 1)
   }

   return (
      <div
         className={classes._}
         onClick={() => {
            navigate(`/estate/${id}`)
            webApp.MainButton.setText(text[lang].textMainBtn)
            webApp.MainButton.show()
         }}
      >
         <img src={image} alt='house' className={classes.imageHouse} />
         <div className={classes.info}>
            <div className={classes.block}>
               <p className={classes.price}>
                  {formatNumber(initMinPrice) ?? 0} {currency}
               </p>
            </div>
            <div className={classes.wrapperText}>
               <div className={classes.block_low}>
                  <p className={classes.loc}>{name}</p>
               </div>
            </div>
         </div>

         {favorite ? (
            <HeartIcon
               onClick={(e) => {
                  e.stopPropagation()
                  updateFav()
               }}
            >
               <img src={RedHeart} alt='heart' />
            </HeartIcon>
         ) : (
            <WhiteHeartIcon
               onClick={(e) => {
                  e.stopPropagation()
                  updateFav()
               }}
            >
               <img src={Heart} alt='heart' />
            </WhiteHeartIcon>
         )}
      </div>
   )
}

export default Card

const HeartIcon = styled.div`
   position: absolute;
   top: 11px;
   right: 14px;
   cursor: pointer;
   height: 34px;
   img {
      height: 100%;
   }
`

const WhiteHeartIcon = styled.div`
   position: absolute;
   top: 14px;
   right: 14px;
   cursor: pointer;
   height: 27px;
   img {
      height: 100%;
   }
`
