// middleware/index.js
import appMiddleware from './appMiddleware'
import checkDataValidityMiddleware from './checkDataValidityMiddleware'
import filterMiddleware from './filterMiddleware'
import catalogMiddleware from './catalogMiddleware'
import estateMiddleware from './estateMiddleware'
import propertyMiddleware from './propertyMiddleware'

const combinedMiddleware = (cloudStorageResult, telegramResult) => {
   return (store) => (next) => (action) => {
      appMiddleware(cloudStorageResult, telegramResult)(store)(next)(action)
      checkDataValidityMiddleware(cloudStorageResult, telegramResult)(store)(next)(action)
      filterMiddleware(cloudStorageResult, telegramResult)(store)(next)(action)
      catalogMiddleware(cloudStorageResult, telegramResult)(store)(next)(action)
      estateMiddleware(cloudStorageResult, telegramResult)(store)(next)(action)
      propertyMiddleware(cloudStorageResult, telegramResult)(store)(next)(action)
   }
}

export default combinedMiddleware
