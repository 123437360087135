import $api from '../../http'
import { estateLoaded } from '../actions/estateActions'

export const getEstateData = (useCloudStorageResult) => async (dispatch, getState) => {
   try {
      const { setItem, getItemAsync } = useCloudStorageResult
      const id = await getItemAsync('estate')
      if (id) {
         const response = await $api.get(`/complex/${id}`)
         if (response.status === 200) {
         }

         dispatch(estateLoaded(response))
      }
   } catch (error) {
      console.error('Error fetching estate data:', error)
   }
}
