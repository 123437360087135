/* eslint-disable jsx-a11y/anchor-is-valid */
import classes from './GridBotPage.module.css'
import GridBotImage from '../../image/GridBot.svg'
import Ellipse from '../../image/ellipse.svg'
import GridBotLink from '../../components/gridBotLink/GridBotLink'
import PayToIcon from '../../image/PayToIcon.svg'
import PayFromIcon from '../../image/FromPay.svg'
import WalletIcon from '../../image/WalletIcon.svg'
import PeopleIcon from '../../image/PeopleIcon.svg'
import Modal from '../../components/modal/Modal'
import { useMemo, useState } from 'react'
import ModalDeposit from '../../components/modalsGrid/ModalDeposit'
import ModalFromCash from '../../components/modalsGrid/ModalFromCash'
import ModalBalanceInfo from '../../components/modalsGrid/ModalBalanceInfo'
import ModalReferalInfo from '../../components/modalsGrid/ModalReferalInfo'

const GridBotPage = () => {
   const linkActionsArr = [
      { name: 'Внести средства на счёт бота', imgUrl: PayToIcon, label: 'Пополнение' },
      { name: 'Вывести средства', imgUrl: PayFromIcon, label: 'Вывод' },
      { name: 'Посмотреть баланс', imgUrl: WalletIcon, label: 'Баланс' },
      { name: 'Реферальная структура', imgUrl: PeopleIcon, label: 'Реферал' },
   ]

   const [showFullText, setShowFullText] = useState(false)

   const [activeModal, setActiveModal] = useState('')

   const chekModal = useMemo(() => {
      switch (activeModal) {
         case 'Пополнение':
            return <ModalDeposit />
         case 'Вывод':
            return <ModalFromCash />
         case 'Баланс':
            return <ModalBalanceInfo />
         case 'Реферал':
            return <ModalReferalInfo />
         default:
            return
      }
   }, [activeModal])

   return (
      <div className={classes.wrapper}>
         <div className={'wrapper'}>
            <div className={classes.headerImage}>
               <img src={GridBotImage} alt='griddBot' className={classes.botImg} />
               <img src={Ellipse} alt='Ellipse' className={classes.ellipse} />
               <div className={classes.headerTextWrapper}>
                  <div className={classes.headerTitle}>Grid-бот</div>
                  <div className={classes.headerDescr}>
                     {showFullText
                        ? 'Встречайте новое слово в мире криптовалют — Grid-бот! Хотите максимизировать свои доходы на криптовалютном рынке с помощью искусственного интеллекта? С Grid-ботом это легко! Наш инновационный бот, основанный на передовых технологиях искусственного интеллекта, автоматически создает сетку ордеров для торговли криптовалютами, обеспечивая стабильный и эффективный способ заработка. Забудьте о сложностях ручного трейдинга! Grid-бот, снабженный мощными алгоритмами искусственного интеллекта, самостоятельно анализирует рынок, следит за изменениями цен и автоматически выполняет сделки в соответствии с вашими предпочтениями. Это идеальное решение для новичков и опытных трейдеров! Надежность, высокая производительность и простота использования — вот основные преимущества нашего Grid-бота, который объединяет силу криптовалют и искусственного интеллекта. Присоединяйтесь к нам сегодня и начните зарабатывать на криптовалюте легко и удобно! Подключайтесь к будущему финансовых технологий с Grid-ботом — вашим надежным партнером на пути к финансовой независимости!'
                        : 'Подключайтесь к будущему финансовых технологий с Grid-ботом — вашим надежным партнером на пути к финансовой независимости!'}
                  </div>
                  <div
                     className={classes.headerInfo}
                     onClick={() => setShowFullText(!showFullText)}
                  >
                     {showFullText ? 'Скрыть' : 'Подробнее'}
                  </div>
               </div>
            </div>
            {linkActionsArr.map((item) => (
               <GridBotLink
                  key={item.name}
                  {...item}
                  handleClick={() => {
                     setActiveModal(item.label)
                     setShowFullText(false)
                  }}
               />
            ))}
            {!!activeModal.length && (
               <Modal onCloseModal={() => setActiveModal('')}>{chekModal}</Modal>
            )}
         </div>
      </div>
   )
}

export default GridBotPage
