import React from 'react'
import Card from '../card/Card'
import classes from './Catalog.module.css'

const Catalog = ({ items, isFav, clickFav, setcCickFav }) => {
   return (
      <div className={classes._ + ' wrapper'}>
         {items?.map((card) => (
            <Card
               key={card?.id}
               {...card}
               isFav={isFav}
               clickFav={clickFav}
               setcCickFav={setcCickFav}
            />
         ))}
      </div>
   )
}

export default Catalog
