import React, { useMemo, useState, useEffect } from 'react'
import classes from './SotkaCardItem.module.css'
// import SotkaCard from '../../image/SotkaCard.svg'
// import InfoSotka1 from '../../image/InfoSotka1.svg'
// import InfoSotka2 from '../../image/InfoSotka2.svg'
// import InfoSotka3 from '../../image/InfoSotka3.svg'
// import InfoSotka4 from '../../image/InfoSotka4.svg'
import $api from '../../http'
import { useParams } from 'react-router-dom'
import PageSpinner from '../pageSpinner/PageSpinner'

const SotkaCardItem = ({ setMainBtnApiText, setMainBtnApiLink }) => {
   const [investCard, setInvestCard] = useState({})

   const [isLoading, setIsLoading] = useState(false)

   useEffect(() => {
      setMainBtnApiText(investCard.btn_text)
      setMainBtnApiLink(investCard.btn_link)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [investCard])

   const params = useParams()

   useMemo(async () => {
      setIsLoading(true)
      try {
         const response = await $api.get(`/investments/${params.id}`)
         setInvestCard(response.data)
      } finally {
         setIsLoading(false)
      }
   }, [params.id])

   return (
      <>
         {isLoading ? (
            <div className={classes.wrapper}>
               <PageSpinner />
            </div>
         ) : (
            <>
               <div className={classes.wrapper}>
                  <div className='wrapper'>
                     <img src={investCard.main_image} alt='sotka' className={classes.sotkaImg} />
                     <div className={classes.infoWrapper}>
                        <div className={classes.infoItem}>
                           <div className={classes.infoTitle}>Инвестиции</div>
                           <div className={classes.infoValue}>{`от ${investCard.price}₽`}</div>
                        </div>{' '}
                        <div className={classes.infoItem}>
                           <div className={classes.infoTitle}>Ниша</div>
                           <div className={classes.infoValue}>
                              {investCard?.types?.[0] ?? 'Не указано'}
                           </div>
                        </div>{' '}
                        <div className={classes.infoItem}>
                           <div className={classes.infoTitle}>Доходность</div>
                           <div
                              className={classes.infoValue}
                           >{`${investCard.profit_start} - ${investCard.profit_end}% / мес`}</div>
                        </div>
                     </div>
                     <div className={classes.descrWrapper}>
                        {investCard?.description && (
                           <>
                              <div className={classes.mainTitle}>Описание</div>
                              <div
                                 className={classes.text}
                                 dangerouslySetInnerHTML={{ __html: investCard?.description }}
                              />
                           </>
                        )}

                        {investCard?.workflow && (
                           <>
                              <div className={classes.title}>Как это работает?</div>
                              <div
                                 className={classes.text}
                                 dangerouslySetInnerHTML={{ __html: investCard?.workflow }}
                              />
                           </>
                        )}
                        {investCard?.images?.length ? (
                           <img
                              src={investCard?.images[0]}
                              alt='NFT'
                              className={classes.imgItems}
                           />
                        ) : (
                           <></>
                        )}

                        {investCard.advantage && (
                           <div
                              className={classes.warningInfo}
                              dangerouslySetInnerHTML={{ __html: investCard?.advantage }}
                           />
                        )}
                        {investCard.reliability && (
                           <>
                              <div className={classes.title}>Надежность и доходность</div>
                              <div
                                 className={classes.text}
                                 dangerouslySetInnerHTML={{ __html: investCard?.reliability }}
                              />
                           </>
                        )}

                        {/* <div className={`${classes.text} ${classes.disText}`}>
                  <div className={classes.imgInfo}>
                     <img src={InfoSotka1} alt='moutin' />
                  </div>
                  MOC Социальный эко-проект "Дом Кедра": создание экологически чистых поселений и
                  развитие туризма.
               </div>
               <div className={`${classes.text} ${classes.disText}`}>
                  <div className={classes.imgInfo}>
                     <img src={InfoSotka2} alt='house' />
                  </div>
                  MOC Сеть ретрит-центров: строительство оздоровительных и духовных центров для
                  отдыха и саморазвития.
               </div>
               <div className={`${classes.text} ${classes.disText}`}>
                  <div className={classes.imgInfo}>
                     <img src={InfoSotka3} alt='tree' />
                  </div>
                  MOC Маркетплейс эко-товаров из Сибири: продвижение экологически чистых продуктов и
                  ремесленных изделий местного производства.
               </div>
               <div className={`${classes.text} ${classes.disText}`}>
                  <div className={classes.imgInfo}>
                     <img src={InfoSotka4} alt='aducation' />
                  </div>
                  MOC Образовательные форумы: организация конференций и семинаров по актуальным
                  вопросам экономики и личностного роста.
               </div> */}
                        {investCard?.benefit && (
                           <div
                              className={classes.greenInfo}
                              dangerouslySetInnerHTML={{ __html: investCard?.benefit }}
                           />
                        )}

                        {investCard?.history && (
                           <div
                              className={classes.text}
                              dangerouslySetInnerHTML={{ __html: investCard?.history }}
                           />
                        )}
                        {investCard?.conclusion && (
                           <div
                              className={classes.text}
                              dangerouslySetInnerHTML={{ __html: investCard?.conclusion }}
                           />
                        )}
                     </div>
                  </div>
               </div>
            </>
         )}
      </>
   )
}

export default SotkaCardItem
