export const SET_PRICE_RANGE = 'SET_PRICE_RANGE'
export const SET_FILTER_OPTION = 'SET_FILTER_OPTION'

export const FETCH_FILTERS = 'FETCH_FILTERS'
export const FETCH_FILTERS_SUCCESS = 'FETCH_FILTERS_SUCCESS'

export const setPriceRange = (range) => ({
   type: SET_PRICE_RANGE,
   payload: range,
})
export const setFilterOption = (filterId, options) => ({
   type: SET_FILTER_OPTION,
   payload: { filterId, options },
})
export const fetchFilters = () => ({
   type: FETCH_FILTERS,
})
export const fetchFiltersSuccess = (
   propertyTypes,
   countries,
   residentialRealEstateTypes,
   commercialRealEstateTypes,
   paymentMethods,
   investionMethods,
   biz,
) => ({
   type: FETCH_FILTERS_SUCCESS,
   payload: {
      propertyTypes: propertyTypes,
      countries: countries,
      residentialRealEstateTypes: residentialRealEstateTypes,
      commercialRealEstateTypes: commercialRealEstateTypes,
      paymentMethods: paymentMethods,
      investionMethods: investionMethods,
      biz: biz,
   },
})
