import axios from 'axios'
import { api_addr } from '../store/thunks/urls'

const $api = axios.create({
   withCredentials: true,
   baseURL: api_addr,
})

$api.interceptors.request.use((config) => {
   config.headers.Authorization = `Bearer ${window.Telegram.WebApp.initData}`
   return config
})

export default $api
