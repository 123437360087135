import classes from './ModalsGrid.module.css'
import PaymentCard from '../../image/PaymentCard.svg'
import styled from 'styled-components'
import { useState } from 'react'
import $api from '../../http'

const ModalDepProfile = ({ onCloseModal, theme }) => {
   const [amountBalance, setAmountBalance] = useState(null)

   const paymentFn = async () => {
      const responce = await $api.post('/top_up_balance', { amount: amountBalance })

      if (responce?.statusText === 'OK' || responce?.status === 200) {
         const paymentUrl = responce?.data?.payment_url

         if (paymentUrl) {
            window.open(paymentUrl, '_blank')
         }
      }
      onCloseModal()
   }

   return (
      <div className={theme === 'dark' ? classes.darkWrapper : classes.wrapper}>
         <img src={PaymentCard} alt='PaymentCard' />
         <Title className={classes.title}>
            Пополнить баланс
            <br />
            Пополнение баланса занимает до 5 минут!
         </Title>
         <Input
            type='number'
            value={amountBalance}
            onChange={(e) => setAmountBalance(e.target.value)}
            placeholder='100₽'
         />
         <Submit onClick={paymentFn} theme={theme}>
            Отправить запрос
         </Submit>
      </div>
   )
}

export default ModalDepProfile

const Title = styled.div`
   text-align: center;
   margin-top: 10px;
`

const Input = styled.input`
   padding: 10px;
   width: 90%;
   border-radius: 20px;
   border: none;
   background: #f1f1f1;
   margin-top: 20px;
   text-align: center;
   font-family: system-ui;
`

const Submit = styled.div`
   font-size: 14px;
   font-weight: 400;
   line-height: 18.2px;
   letter-spacing: -0.02em;
   text-align: center;
   color: ${(props) => (props.theme === 'dark' ? '#000' : '#fff')};
   width: 90%;
   padding: 9px;
   background: #beff8b;
   border-radius: 20px;
   margin-top: 15px;
   transition: all 0.3s ease;
   box-shadow: 0px 0px 0px gray;
   &:hover {
      cursor: pointer;
      box-shadow: 1px 2px 20px gray;
   }
`
