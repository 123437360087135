import axios from 'axios';
import { setCurrencyRates } from '../actions/currencyActions';
import { setCurerncyLastUpdate } from '../actions/timeActions';

const fetchCurrencyRates = (useCloudStorageResult, useTelegramResult) => {
  return async (dispatch) => {
    try {
      const { setItem, getItemAsync } = useCloudStorageResult;  
      const { webApp, lang } = useTelegramResult;
      const response = await axios.get('https://open.er-api.com/v6/latest/RUB');
      const rates = response.data.rates;
      const keys = ['RUB', 'USD'];
      const filteredRates = keys.reduce((result, key) => {
        if (rates.hasOwnProperty(key)) {
          result[key] = rates[key];
        }
        return result;
      }, {});
      dispatch(setCurrencyRates(filteredRates));
      setItem('currencyRates', JSON.stringify(filteredRates));

      const currentTime = Date.now();
      dispatch(setCurerncyLastUpdate(currentTime));
      setItem('currencyLastUpdate', currentTime.toString());

    } catch (error) {
      console.error('Error fetching currency rates:', error);
    }
  };
};

const restoreCurrencyRates = (useCloudStorageResult, useTelegramResult) => {
  return async (dispatch) => {
    try {
      const { setItem, getItemAsync } = useCloudStorageResult;  
      const { webApp, lang } = useTelegramResult;

      const currencyRatesString = await getItemAsync('currencyRates');
      const currencyRates = JSON.parse(currencyRatesString);
      dispatch(setCurrencyRates(currencyRates));
      
    } catch (error) {
      console.error('Error fetching currency rates:', error);
    }
  };
};

export const checkCurrencyDataValidity = (useCloudStorageResult, useTelegramResult) => {
  return async (dispatch, getState) => {
      const { setItem, getItemAsync } = useCloudStorageResult; 
      const state = getState();
      var lastUpdate = state?.time?.currencyLastUpdate;
      if (!lastUpdate){
        lastUpdate = await getItemAsync('currencyLastUpdate');
      }
      const currentTime = Date.now();
      const oneHour = 3600000; // 1 час в миллисекундах
  
      if (!lastUpdate || (currentTime - lastUpdate) > oneHour) {
        await dispatch(fetchCurrencyRates(useCloudStorageResult, useTelegramResult));
      }
      else {
        await dispatch(restoreCurrencyRates(useCloudStorageResult, useTelegramResult));
      }
    };
  };