import { 
    INIT_ESTATE,
    ESTATE_LOADED,
 } from "../actions/estateActions";

 const initialState = {
    item: {},
    loading: false,
    error: null
  };
  const estateReducer = (state = initialState, action) => {
    switch (action.type) {
      case INIT_ESTATE:
        return {
          ...state,
          loading: true,
          error: null
        };
    case ESTATE_LOADED:
        return{
           ...state,
           loading: false,
           item: action.payload,
        }
      default:
        return state;
    }
  };
  
  export default estateReducer;