import React, { useEffect, useState } from 'react'
import classes from './Filter.module.css'
import Select from './select/Select'
import { text } from '../../translate'
import { useTelegram } from '../../hooks/useTelegram'
import Range from './range/Range'

const FilterInvestion = ({ investData, setFilterData, isFav }) => {
   const { lang } = useTelegram()

   const investionMethods = [
      { id: 1, name: text[lang].filter.investionMethod.usd, value: 'usd', fv: 'usd' },
      { id: 2, name: text[lang].filter.investionMethod.rub, value: 'rub', fv: 'rub' },
      { id: 3, name: text[lang].filter.investionMethod.crypto, value: 'crypto', fv: 'crypto' },
   ]

   const biz = [
      { id: 2, name: text[lang].filter.biz.nedvijka, value: 'estate', fv: 'nedvijka' },
      { id: 3, name: text[lang].filter.biz.crypto, value: 'crypto', fv: 'crypto' },
   ]

   const [inputFromSize, setInputFromSize] = useState('')
   const [inputToSize, setInputToSize] = useState('')
   const [inputFromTime, setInputFromTime] = useState('')
   const [inputToTime, setInputToTime] = useState('')
   const [niches, setNiches] = useState('')
   const [types, setTypes] = useState('')

   useEffect(() => {
      let res = investData

      if (!!inputFromSize && !!inputToSize) {
         res = res.filter((item) => item.price >= inputFromSize && item.price <= inputToSize)
      }
      if (!!inputFromSize) {
         res = res.filter((item) => item.price >= inputFromSize)
      }

      if (!!inputToSize) {
         res = res.filter((item) => item.price <= inputToSize)
      }
      if (!!inputFromTime && !!inputToTime) {
         res = res.filter((item) => item.period >= inputFromTime && item.period <= inputToTime)
      }
      if (!!inputFromSize) {
         res = res.filter((item) => item.period >= inputFromSize)
      }

      if (!!inputToSize) {
         res = res.filter((item) => item.period <= inputToSize)
      }

      if (!!niches) {
         res = res.filter((item) => item.niches.some((biz) => biz.includes(niches)))
      }

      if (!!types) {
         res = res.filter((item) => item.types.some((type) => type.includes(types)))
      }

      if (isFav) {
         res = res.filter((item) => item.is_favorite)
      }

      setFilterData(res)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [inputFromSize, inputFromTime, inputToSize, inputToTime, investData, niches, isFav, types])

   return (
      <div className={classes._ + ' wrapper'}>
         <Range
            title={'Размер инвестиции'}
            inputFrom={inputFromSize}
            setInputFrom={setInputFromSize}
            inputTo={inputToSize}
            setInputTo={setInputToSize}
         />
         <Range
            title={'Срок инвестиции'}
            time={true}
            inputFrom={inputFromTime}
            setInputFrom={setInputFromTime}
            inputTo={inputToTime}
            setInputTo={setInputToTime}
         />

         <Select
            id={1}
            name={text[lang].filter.investionMethod.name}
            options={investionMethods || []}
            smallSelect={true}
            state={types}
            setState={setTypes}
         />
         <Select
            id={2}
            name={text[lang].filter.biz.name}
            options={biz || []}
            multi={true}
            state={niches}
            setState={setNiches}
         />
      </div>
   )
}

export default FilterInvestion
