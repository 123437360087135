import classes from './ReferalUser.module.css'
import styled from 'styled-components'

const ReferalUser = ({ profit, user, theme }) => {
   const { avatar_url, first_name, last_name, telegram_username, created_at } = user
   var name = `${first_name ? first_name : ''} ${last_name ? last_name : ''}`
   const nick = telegram_username
   if (name === '' || name === null) {
      name = nick
   }

   const formatDate = (isoDate) => {
      const date = new Date(isoDate)

      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()

      return `${day}.${month}.${year}`
   }
   return (
      <div className={classes.wrapper}>
         <div className={classes.userInfo}>
            {avatar_url ? (
               <img src={avatar_url} alt='avatar' className={classes.image} />
            ) : (
               <InitialPhoto theme={theme} className={classes.image}>
                  {name.split(' ').length > 1
                     ? `${name[0][0] || ''} ${name.split(' ')[1][0] || ''}`
                     : name[0][0] || ''}
               </InitialPhoto>
            )}
            <div className={classes.contact}>
               <div className={`${classes.name} ${theme === 'light' ? classes.nameText : ''}`}>
                  {name?.length > 14 ? name?.slice(0, 13) + '...' : name}
               </div>
               <div className={classes.nick}>
                  {nick?.length > 14 ? nick?.slice(0, 13) + '...' : nick}
               </div>
            </div>
         </div>
         <div
            style={{ width: '25%', minWidth: '25%', maxWidth: '25%', textAlign: 'center' }}
            className={theme === 'dark' ? classes.darkText : ''}
         >
            {formatDate(created_at)}
         </div>
         <div
            style={{ width: '30%', minWidth: '30%', maxWidth: '30%', textAlign: 'right' }}
            className={`${theme === 'dark' ? classes.darkText : ''}`}
         >
            {profit ?? 0} ₽
         </div>
      </div>
   )
}

export default ReferalUser

const InitialPhoto = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   letter-spacing: -1px;
   font-size: 18px;
   border-radius: 50%;
   height: 55px;
   width: 55px;
   font-weight: 600;
   ${(props) =>
      props.theme === 'light'
         ? 'background: #b05edc; color: #000;'
         : 'background: #9f14b6; color: #fff;'}
`
