import $api from '../../http'
import { text } from '../../translate'
import { fetchFiltersSuccess } from '../actions/filterActions'
import { applyFilters } from '../actions/catalogActions'

export const filtrate = async (useCloudStorageResult, useTelegramResult) => {
   const { lang } = useTelegramResult
   const { setItem, getItemAsync } = useCloudStorageResult

   return async (dispatch, getState) => {
      try {
         const state = getState()
         const options = state?.filter?.selectedOptions
         const data = state?.catalog?.cards
         const priceRange = state?.filter?.priceRange
         if (data && data.length > 0) {
            const filtered = filterData(data, options, priceRange)
            dispatch(applyFilters(filtered))
         }
      } catch (error) {
         console.error('Error fetching filters data:', error)
      }
   }
}
const filterData = (data, selectedOptions, priceRange) => {
   // Преобразуем selectedOptions в удобный для поиска объект
   const filters = Object.keys(selectedOptions).reduce((acc, filterId) => {
      acc[filterId] = selectedOptions[filterId].map((option) => option.fv)
      return acc
   }, {})
   // Фильтрация данных
   const hasFilters = Object.keys(filters).some((filterId) => filters[filterId].length > 0)
   return data.filter((item) => {
      // Если фильтры не установлены, показываем все данные
      if (!hasFilters && !priceRange.from && !priceRange.to) {
         return true
      }
      // Проверяем соответствие по каждому фильтру
      const filterMatches = Object.entries(filters).every(([filterId, filterValues]) => {
         switch (parseInt(filterId, 10)) {
            case 1: // propertyTypes
               return filterValues.includes(item.propertyType)
            case 2: // residentialRealEstateTypes
               return filterValues.includes(item.residentialRealEstateType)
            case 3: // commercialRealEstateTypes
               return filterValues.includes(item.commercialRealEstateType)
            case 4: // countries
               return filterValues.includes(item.country)
            case 5: // paymentMethods
               return filterValues.includes(item.paymentMethod)
            case 6: // investionMethods
               return filterValues.includes(item.investionMethods)
            case 7: // biz
               return filterValues.includes(item.biz)
            default:
               return true
         }
      })
      const priceMatches =
         (!parseInt(priceRange.from.replace(/\s/g, '')) ||
            item.price >= parseInt(priceRange.from.replace(/\s/g, ''))) &&
         (!parseInt(priceRange.to.replace(/\s/g, '')) ||
            item.price <= parseInt(priceRange.to.replace(/\s/g, '')))
      if (!hasFilters && !filterMatches) {
         return priceMatches
      }
      return filterMatches && priceMatches
   })
}
export const fetchFiltersData = (useCloudStorageResult, useTelegramResult) => {
   const { lang } = useTelegramResult
   const { setItem, getItemAsync } = useCloudStorageResult

   const propertyTypes = [
      { id: 1, name: text[lang].filter.propertyTypes.residential, fv: 'residential' },
      { id: 2, name: text[lang].filter.propertyTypes.commercial, fv: 'commercial' },
      { id: 3, name: text[lang].filter.propertyTypes.top, fv: 'top' },
   ]
   const countries = [
      { id: 1, name: text[lang].filter.country.Russia, fv: 'Russia' },
      { id: 2, name: text[lang].filter.country.Turkey, fv: 'Turkey' },
      { id: 3, name: text[lang].filter.country.NorthernCyprus, fv: 'NorthernCyprus' },
   ]
   const residentialRealEstateTypes = [
      { id: 1, name: text[lang].filter.residentialRealEstateTypes.flat, fv: 'flat' },
      { id: 2, name: text[lang].filter.residentialRealEstateTypes.village, fv: 'village' },
      { id: 3, name: text[lang].filter.residentialRealEstateTypes.townHouse, fv: 'townHouse' },
      { id: 4, name: text[lang].filter.residentialRealEstateTypes.elite, fv: 'elite' },
      { id: 5, name: text[lang].filter.residentialRealEstateTypes.cheap, fv: 'cheap' },
      { id: 6, name: text[lang].filter.residentialRealEstateTypes.nearBySea, fv: 'nearBySea' },
   ]
   const commercialRealEstateTypes = [
      { id: 1, name: text[lang].filter.commercialRealEstateTypes.var1, fv: 'var1' },
      { id: 2, name: text[lang].filter.commercialRealEstateTypes.var2, fv: 'var2' },
   ]
   const paymentMethods = [
      { id: 1, name: text[lang].filter.paymentMethod.cash, fv: 'cash' },
      { id: 2, name: text[lang].filter.paymentMethod.split, fv: 'split' },
      { id: 3, name: text[lang].filter.paymentMethod.mortgage, fv: 'mortgage' },
      { id: 4, name: text[lang].filter.paymentMethod.crypto, fv: 'crypto' },
   ]
   const investionMethods = [
      { id: 1, name: text[lang].filter.investionMethod.usd, fv: 'usd' },
      { id: 2, name: text[lang].filter.investionMethod.rub, fv: 'rub' },
      { id: 3, name: text[lang].filter.investionMethod.crypto, fv: 'crypto' },
   ]

   const biz = [
      { id: 2, name: text[lang].filter.biz.nedvijka, fv: 'nedvijka' },
      { id: 3, name: text[lang].filter.biz.crypto, fv: 'crypto' },
   ]

   return async (dispatch, getState) => {
      try {
         const state = getState()
         const { setItem, getItemAsync } = useCloudStorageResult
         const { webApp, lang } = useTelegramResult
         //const responseFilterData = await $api.get('/user');
         //const responseRewardsData = await $api.get('/rewards');
         dispatch(
            fetchFiltersSuccess(
               propertyTypes,
               countries,
               residentialRealEstateTypes,
               commercialRealEstateTypes,
               paymentMethods,
               investionMethods,
               biz,
            ),
         )
      } catch (error) {
         console.error('Error fetching filters data:', error)
      }
   }
}
