import './App.css'
import React, { useCallback, useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import MainPage from './pages/mainPage/MainPage'
import ProfilePage from './pages/profilePage/ProfilePage'
import PropertyPage from './pages/propertyPage/PropertyPage'
import InvestionPage from './pages/investionPage/InvestionPage'
import SotkaCardItem from './components/sotkaCardItem/SotkaCardItem'
import GridBotPage from './pages/gridBotPage/GridBotPage'
import Estate from './components/estate/Estate'
import { useTelegram } from './hooks/useTelegram'
import { text } from './translate'
import AcademyPage from './pages/academyPage/AcademyPage'
import AcademyCard from './components/academyCard/AcademyCard'
import EventsPage from './pages/eventsPage/EventsPage'
import EventCard from './components/eventCard/EventCard'
import $api from './http'

const App = () => {
   const { webApp, lang, theme } = useTelegram()
   const navigate = useNavigate()

   const [mainBtnApiText, setMainBtnApiText] = useState('')
   const [mainBtnApiLink, setMainBtnApiLink] = useState('')

   const onMainClick = useCallback(() => {
      if (window.location.pathname?.includes('sotka-card')) {
         // window.location.href = mainBtnApiLink
         webApp.openLink(mainBtnApiLink)
      } else {
         // window.location.href = 'https://t.me/Generatoridey'
         webApp.openLink('https://t.me/Generatoridey')
      }
      return
   }, [mainBtnApiLink, webApp])

   const onBackClick = useCallback(() => {
      if (window.location.pathname === '/') {
         webApp.BackButton.hide()
      }
      webApp.MainButton.hide()
      navigate(-1)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [webApp.BackButton, window.location.pathname])

   const [userBack, setUserBack] = useState()

   // eslint-disable-next-line react-hooks/exhaustive-deps
   useEffect(async () => {
      const responseUserData = await $api.get('/user')
      setUserBack(responseUserData.data)
   }, [])

   // setInterval(async () => {
   //    const responseUserData = await $api.get('/user')
   //    setUserBack(responseUserData.data)
   // }, 10000)

   useEffect(() => {
      const intervalId = setInterval(async () => {
         try {
            const responseUserData = await $api.get('/user')
            setUserBack(responseUserData.data)
         } catch (error) {
            console.error('Error fetching user data:', error)
         }
      }, 10000)
      return () => clearInterval(intervalId)
   }, [])

   useEffect(() => {
      webApp.ready()
      webApp.enableClosingConfirmation()
      webApp.expand()

      webApp.BackButton.onClick(onBackClick)
      webApp.MainButton.onClick(onMainClick)

      // webApp.MainButton.setText(onTextBtn)

      if (window.location.pathname?.includes('sotka-card')) {
         webApp.MainButton.setText(mainBtnApiText)
      } else {
         webApp.MainButton.setText(text[lang].textMainBtn)
      }

      return () => {
         webApp.BackButton.offClick(onBackClick)
         webApp.MainButton.offClick(onMainClick)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [webApp, mainBtnApiText])
   return (
      <div className='App'>
         <Routes>
            <Route index element={<MainPage userBack={userBack} />} />
            <Route path='/profile' element={<ProfilePage userBack={userBack} lang={lang} />} />
            <Route path='/property' element={<PropertyPage />} />
            <Route path='/investment' element={<InvestionPage />} />
            <Route
               path='/sotka-card/:id'
               element={
                  <SotkaCardItem
                     setMainBtnApiText={setMainBtnApiText}
                     setMainBtnApiLink={setMainBtnApiLink}
                  />
               }
            />
            <Route path='/grid-bot' element={<GridBotPage />} />
            <Route path='/estate/:id' element={<Estate setMainBtnApiText={setMainBtnApiText} />} />
            <Route path='/academy' element={<AcademyPage />} />
            <Route
               path='/academy-card/:id'
               element={<AcademyCard user={userBack} theme={theme} />}
            />
            <Route path='/biz-event' element={<EventsPage />} />
            <Route
               path='/biz-event-card/:id'
               element={<EventCard user={userBack} theme={theme} />}
            />
         </Routes>
      </div>
   )
}

export default App
