import {
  INIT_CATALOG, 
  FETCH_CATALOG_DATA, 
  FINISH_FETCHING_CATALOG_DATA,
  MARK_FAVORITES,
  APPLY_FILTERS,
  TOGGLE_FAVORITE,
  } from '../actions/catalogActions';

const initialState = {
    loading: false,
    error: '',
    cards: null,
    filteredCards: null,
    favorites: [],
};

const catalogReducer = (state = initialState, action) => {
    switch (action.type) {
      case INIT_CATALOG:
            return{
                ...state,
            };
        case FETCH_CATALOG_DATA:
            return{
                ...state,
            }
        case FINISH_FETCHING_CATALOG_DATA:
            return{
                ...state,
                loading: false,
                cards: action.payload,
                filteredCards: action.payload,
            }
      case MARK_FAVORITES:
        const updatedCards = state.cards.map(card => ({
          ...card,
          isFavorite: action.payload.includes(card.id), // Помечаем карточки в избранных
        }));
        return {
          ...state,
          favorites: action.payload,
          сards: updatedCards,
          filteredCards: updatedCards, //Обновляем весь список
        };
      case APPLY_FILTERS: 
        return {
          ...state,
          filteredCards: action.payload,
        };
      case TOGGLE_FAVORITE:
        return {
          ...state,
          сards: state.сards.map((card) =>
            card.id === action.payload ? { ...card, isFavorite: !card.isFavorite } : card
          ),
          filteredCards: state.filteredCards.map((card) =>
          card.id === action.payload ? { ...card, isFavorite: !card.isFavorite } : card
        ),
        };
      default:
      return state;
    }
  };
  
  export default catalogReducer;