import { useEffect, useState } from 'react'
import classes from './ReferalStats.module.css'
import ArrowSelect from '../../image/arrow.svg'
import ReferalUser from './ReferalUser/ReferalUser'
import styled from 'styled-components'
import $api from '../../http'

const ReferalStats = ({ theme }) => {
   const [line, setLine] = useState(1)
   const [isOpen, setIsOpen] = useState(false)

   const [rewards, setRewards] = useState([])

   useEffect(() => {
      const fetchRewards = async () => {
         try {
            const response = await $api.get('/rewards')
            setRewards(response.data)
         } catch (error) {
            console.error('Ошибка загрузки данных:', error)
         }
      }
      fetchRewards()
   }, [])

   console.log('payments', rewards)

   const nameLine = [
      {
         name: 'Линия 1',
         value: 1,
      },
      {
         name: 'Линия 2',
         value: 2,
      },
      {
         name: 'Линия 3',
         value: 3,
      },
   ]

   return (
      <Wraper className={classes.wrapper + ' wrapper'}>
         <Title theme={theme}>Реферальная статистика</Title>

         <Select theme={theme} onClick={() => setIsOpen(!isOpen)}>
            Линия {line}
            <img src={ArrowSelect} alt='ArrowSelect' />
            {isOpen && (
               <>
                  <ClosedBlock />
                  <OptionsWrapper theme={theme} className={classes.optionsWrapper}>
                     {nameLine.map((item) => (
                        <Option key={item.value} onClick={() => setLine(item.value)} theme={theme}>
                           {item.name}
                        </Option>
                     ))}
                  </OptionsWrapper>
               </>
            )}
         </Select>
         <HeaderTable>
            <p style={{ minWidth: '39%' }}>Пользователь</p>
            <p>Дата регистрации</p>
            <p style={{ textAlign: 'right', minWidth: '30%', maxWidth: '30%' }}>
               Реф. вознаграждения
            </p>
         </HeaderTable>
         {rewards &&
            rewards?.filter((item) => item.level === line) &&
            rewards?.filter((item) => item.level === line).length > 0 &&
            rewards
               ?.filter((item) => item.level === line)
               .map((item) => (
                  <ReferalUser key={item?.user?.telegram_id} {...item} theme={theme} />
               ))}
      </Wraper>
   )
}

export default ReferalStats

const ClosedBlock = styled.div`
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: none;
`

const Option = styled.div`
   color: ${(props) => (props.theme === 'light' ? '#11141b' : '#fff')};
   transition: all 0.3s ease;
   &:hover {
      background: #e3e3e3;
   }
`

const HeaderTable = styled.div`
   padding-top: 15px;
   font-size: 9px;
   font-weight: 400;
   line-height: 12px;
   letter-spacing: -0.02em;
   display: flex;
   justify-content: space-between;
   align-items: center;
   color: #5f7385;
`

const OptionsWrapper = styled.div`
   position: absolute;
   top: 53px;
   left: 0px;
   border-radius: 16px;
   border: 1px solid ${(props) => (props.theme === 'light' ? '#11141b' : '#fff')};
   width: 99%;
   z-index: 1;
   transition: all 0.3s ease;
   > div {
      padding: 13px 15px;
      border-bottom: 1px solid ${(props) => (props.theme === 'light' ? '#11141b' : '#fff')};
      &:last-child {
         border-bottom: none;
         border-bottom-left-radius: 16px;
         border-bottom-right-radius: 16px;
      }
      &:first-child {
         border-top-left-radius: 16px;
         border-top-right-radius: 16px;
      }
   }
`

const Title = styled.div`
   font-size: 24px;
   font-weight: 700;
   line-height: 31.2px;
   letter-spacing: -0.02em;
   color: ${(props) => (props.theme === 'light' ? '#11141b' : '#fff')};
`

const Select = styled.div`
   width: 88%;
   padding: 15px 20px;
   border-radius: 30px;
   margin-top: 20px;
   position: relative;
   border: 1px solid ${(props) => (props.theme === 'light' ? '#11141b' : '#fff')};
   display: flex;
   justify-content: space-between;
   align-items: center;
   font-size: 14px;
   font-weight: 400;
   line-height: 16.8px;
   letter-spacing: -0.02em;
   cursor: pointer;
   color: ${(props) => (props.theme === 'light' ? '#11141b' : '#fff')};
   transition: all 0.3s ease;
   img {
      height: 8px;
   }

   &:hover {
      box-shadow: 1px 2px 20px gray;
   }
`

const Wraper = styled.div`
   border-radius: 16px;
   padding: 29px 14px;
   width: 86%;
   margin-top: 40px;
`
