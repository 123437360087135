import $api from '../../http';
import { saveUserInfo, bindButtons, bindMainBtn, bindBackBtn } from '../actions/appActions';
import { setUserLastUpdate } from '../actions/timeActions';

export const fetchUserData = (useCloudStorageResult, useTelegramResult) => { 
    return async (dispatch) => {
        try {
            const { setItem, getItemAsync } = useCloudStorageResult;  
            const { webApp, lang } = useTelegramResult;
            const responseUserData = await $api.get('/user');
            const responseRewardsData = await $api.get('/rewards');
            var userData = responseUserData.data;
            const rewardsData = responseRewardsData.data;
            const updatedLevelsData = addRewardToUser(userData, rewardsData);
            dispatch(saveUserInfo(updatedLevelsData));
            setItem('userData', JSON.stringify(updatedLevelsData));

            const currentTime = Date.now();
            dispatch(setUserLastUpdate(currentTime));
            setItem('userLastUpdate', currentTime.toString());

        }catch (error) {
            console.error('Error fetching user data:', error);
        }
    };
};

const restoreUserData = (useCloudStorageResult, useTelegramResult) => {
    return async (dispatch) => {
      try {
        const { setItem, getItemAsync } = useCloudStorageResult;  
        const { webApp, lang } = useTelegramResult;
  
        const userDataString = await getItemAsync('userData');
        const userData = JSON.parse(userDataString);
        dispatch(saveUserInfo(userData));
        
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  };

export const bindBtns = () => async (dispatch) => {
    dispatch(bindButtons());
    dispatch(bindMainBtn());
    dispatch(bindBackBtn());
}

export const checkUserDataValidity = (useCloudStorageResult, useTelegramResult) => {
    return async (dispatch, getState) => {
        const { setItem, getItemAsync } = useCloudStorageResult; 
        const state = getState();
        var lastUpdate = state?.time?.userLastUpdate;
        if (!lastUpdate){
            lastUpdate = await getItemAsync('userLastUpdate');
        }
        const currentTime = Date.now();
        //const oneHour = 600000; // 10 мин в миллисекундах
        const oneHour = 60; // 10 мин в миллисекундах
        if (!lastUpdate || (currentTime - lastUpdate) > oneHour) {
            await dispatch(fetchUserData(useCloudStorageResult, useTelegramResult));
        }
        else {
            await dispatch(restoreUserData(useCloudStorageResult, useTelegramResult));
        }
    };
};

function addRewardToUser(levelsData, paymentData) {
    paymentData.forEach(({ level, price, payment: { user } }) => {
      const lineKey = `line${level}`;
      if (levelsData.invited_users && levelsData.invited_users[lineKey]) {
        levelsData.invited_users[lineKey] = levelsData.invited_users[lineKey].map(invitedUser => {
          if (invitedUser.telegram_username === user.telegram_username) {
            return {
              ...invitedUser,
              reward: (invitedUser.reward || 0) + price
            };
          }
          return invitedUser;
        });
      }
    });
    return levelsData;
}